import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-allentown-pennsylvania.png'
import image0 from "../../images/cities/scale-model-of-visitor-center-at-steelstacks-in-allentown-pennsylvania.png"
import image1 from "../../images/cities/scale-model-of-moravian-museum-of-bethlehem---1741-gemeinhaus-in-allentown-pennsylvania.png"
import image2 from "../../images/cities/scale-model-of-dorney-park-&-wildwater-kingdom-in-allentown-pennsylvania.png"
import image3 from "../../images/cities/scale-model-of-liberty-bell-museum-in-allentown-pennsylvania.png"
import image4 from "../../images/cities/scale-model-of-historic-bethlehem-museums-&-sites-administrative-office-in-allentown-pennsylvania.png"
import image5 from "../../images/cities/scale-model-of-colonial-industrial-quarter-in-allentown-pennsylvania.png"
import image6 from "../../images/cities/scale-model-of-steelstacks-in-allentown-pennsylvania.png"
import image7 from "../../images/cities/scale-model-of-lehigh-valley-heritage-museum-in-allentown-pennsylvania.png"
import image8 from "../../images/cities/scale-model-of-thunderhawk-in-allentown-pennsylvania.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Allentown'
            state='Pennsylvania'
            image={image}
            lat='40.6022939'
            lon='-75.4714098'
            attractions={ [{"name": "Visitor Center at SteelStacks", "vicinity": "711 E 1st St, Bethlehem", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.61486860000001, "lng": -75.3693417}, {"name": "Moravian Museum of Bethlehem - 1741 Gemeinhaus", "vicinity": "66 W Church St, Bethlehem", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.6188927, "lng": -75.38052119999998}, {"name": "Dorney Park & Wildwater Kingdom", "vicinity": "3830 Dorney Park Rd, Allentown", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 40.5793348, "lng": -75.5315895}, {"name": "Liberty Bell Museum", "vicinity": "622 Hamilton St, Allentown", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.6023783, "lng": -75.47042779999998}, {"name": "Historic Bethlehem Museums & Sites Administrative Office", "vicinity": "74 W Broad St #310, Bethlehem", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.6226365, "lng": -75.3816079}, {"name": "Colonial Industrial Quarter", "vicinity": "459 Old York Rd, Bethlehem", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.6206736, "lng": -75.38360160000002}, {"name": "SteelStacks", "vicinity": "101 Founders Way, Bethlehem", "types": ["point_of_interest", "establishment"], "lat": 40.615262, "lng": -75.36815039999999}, {"name": "Lehigh Valley Heritage Museum", "vicinity": "432 Walnut St, Allentown", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.601923, "lng": -75.46583399999997}, {"name": "Thunderhawk", "vicinity": "3830 Dorney Park Rd, Allentown", "types": ["point_of_interest", "establishment"], "lat": 40.5798971, "lng": -75.53552939999997}] }
            attractionImages={ {"Visitor Center at SteelStacks":image0,"Moravian Museum of Bethlehem - 1741 Gemeinhaus":image1,"Dorney Park & Wildwater Kingdom":image2,"Liberty Bell Museum":image3,"Historic Bethlehem Museums & Sites Administrative Office":image4,"Colonial Industrial Quarter":image5,"SteelStacks":image6,"Lehigh Valley Heritage Museum":image7,"Thunderhawk":image8,} }
       />);
  }
}